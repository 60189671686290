/** @jsx jsx */
import { jsx } from "@emotion/core"

import useNotes from "../hooks/use-notes"
import Layout from "../components/layout"
import NotePreview from "../components/note-preview"

export default function HomePage() {
  const notes = useNotes()

  return (
    <Layout>
      {notes.map(note => (
        <NotePreview key={note.slug} {...note} />
      ))}
    </Layout>
  )
}
