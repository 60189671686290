/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Link } from "gatsby"

import Card, { CardTitle, CardMute } from "./card"
import ReadMore from "./read-more"

export default function NotePreview({ slug, date, title, excerpt }) {
  return (
    <Card>
      <CardMute>{date}</CardMute>
      <CardTitle>
        <Link to={`/notes/${slug}`}>{title}</Link>
      </CardTitle>
      <p>{excerpt}</p>
      <footer>
        <ReadMore to={`/notes/${slug}`} />
      </footer>
    </Card>
  )
}
