/** @jsx jsx */
import { jsx } from "@emotion/core"
import { Link } from "gatsby"

export default function ReadMore({ to, ...restProps }) {
  return (
    <Link
      css={{
        display: "inline-block",
        marginTop: 16,
        padding: 12,
        lineHeight: 1,
        paddingLeft: 16,
        paddingRight: 16,
        border: `1px #66bb6a solid`,
        background: "#81c784",
        "&:hover": {
          background: "#66bb6a",
          textDecoration: "none",
        },
        color: "#FFFFFF",
        textDecoration: "none",
        borderRadius: 3,
      }}
      {...restProps}
      to={to}
    >
      Read More &rarr;
    </Link>
  )
}
