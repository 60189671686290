import { graphql, useStaticQuery } from "gatsby"

export default function useNotes() {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { sourceInstanceName: { eq: "note" } }
        sort: { order: DESC, fields: relativeDirectory }
      ) {
        nodes {
          childMdx {
            frontmatter {
              date
              slug
              title
              excerpt
            }
          }
        }
      }
    }
  `)

  return data.allFile.nodes.map(note => note.childMdx.frontmatter)
}
